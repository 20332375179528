import React from "react"
import * as classNames from "classnames"

const index = ({ name, isSubmitting, className = "", ...props }) => {
  return (
    <button
      className={classNames("button", "is-primary", className, {
        "is-loading": isSubmitting,
      })}
      type="submit"
      {...props}
    >
      {name}
    </button>
  )
}

export default index
