import { useUser } from "contexts/UserContext"
import { UserRole } from "graphql/gql/graphql"

const useIsUserAdmin = () => {
  const { role } = useUser()

  return role && role === UserRole.Admin
}

export default useIsUserAdmin
