import React from "react"
import Link from "next/link"

import AuthenticationGuard from "components/AuthenticationGuard"

import styles from "./navbar.module.scss"
import { Role } from "types/roles"

type MenuItemProps = {
  icon:
    | "search"
    | "service-order"
    | "payout"
    | "piece"
    | "customer"
    | "designer"
    | "user"
    | "settings"
    | "logout"
    | "shipments"
    | "order"
    | "analytics"
    | "marketing"
    | "photoshoots"
    | "discount"
  label: string
  allowedRoles?: Role[]
  href: string
}

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  label,
  href,
  allowedRoles = ["All"],
}) => {
  const iconPath = `/assets/images/icons/${icon}.svg`
  
  const MenuElement = () => (
    <div className={styles.menuItem}>
      <Link href={href} legacyBehavior>
        <a className={styles.menuItemInner}>
          <img src={iconPath} alt={label} />
          <span className={styles.label}>{label}</span>
        </a>
      </Link>
    </div>
  )

  if (!allowedRoles.includes("All")) {
    return (
      <AuthenticationGuard allowedRoles={allowedRoles}>
        <MenuElement />
      </AuthenticationGuard>
    )
  }

  return <MenuElement />
}

export default MenuItem
