import PropTypes from "prop-types"
import { useUser } from "contexts/UserContext"
import { UserRole } from "graphql/gql/graphql"
import { GET_B2B_URL_QUERY } from "components/B2BServices/AdminForm/AdminForm"
import { useQuery } from "@apollo/client"
import { useRouter } from "next/router"
import useIsUserAdmin from "hooks/useIsUserAdmin"

export default function AuthenticationGuard({
  allowedRoles,
  children,
  fallBack = null,
}) {
  const { role: userRole } = useUser()
  const { query, isReady } = useRouter()
  const userIsAdmin = useIsUserAdmin()
  const { data: B2BUrlData, loading: B2BUrlLoading } = useQuery(
    GET_B2B_URL_QUERY,
    {
      variables: { handle: query?.handle },
      skip:
        !isReady ||
        !query?.handle ||
        (!allowedRoles.includes("Services") && !allowedRoles.includes("Admin")),
    }
  )
 

  // user cannot enter without an admin-provided URL
  if (
    !B2BUrlData?.designer?.b2bPageUrl &&
    !B2BUrlLoading &&
    !userIsAdmin &&
    allowedRoles.includes("Services")
  ) {
    return fallBack
  }

  // If logged in user does not have an attached role.
  if (!userRole) {
    return fallBack
  }

  // If logged in user does not fulfill allowed roles.
  if (
    !allowedRoles.includes("All") &&
    !allowedRoles.includes("Services") &&
    !allowedRoles.includes(userRole)
  ) {
    return fallBack
  }

  return <>{children}</>
}

AuthenticationGuard.propTypes = {
  allowedRoles: PropTypes.arrayOf(
    PropTypes.oneOf([
      UserRole.Regular,
      UserRole.Finance,
      UserRole.ServiceOrderUs,
      UserRole.ServiceOrderUk,
      UserRole.ServiceOrderEu,
      UserRole.ServiceOrderAu,
      UserRole.Admin,
      UserRole.DataEntry,
      "Services",
      "All",
    ])
  ),
  fallback: PropTypes.func,
}
