import Link from "next/link"
import Menu from "components/Navbar/Menu"
import styles from "./navbar.module.scss"
import classNames from "classnames"

const index = () => {
  return (
    <nav
      className={styles.navbar}
      role="navigation"
      aria-label="main navigation"
    >
      <div className={styles.background}></div>

      <div className={classNames(styles.logo, "is-clickable")}>
        <Link href="/">
          <img src="/assets/images/logo.png" alt="Logo" />
        </Link>
      </div>
      <Menu />
    </nav>
  )
}

export default index
