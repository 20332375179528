import Head from "next/head"
import Navbar from "../Navbar"
import { useEffect } from "react"
import { useUser } from "contexts/UserContext"
import { useRouter } from "next/router"
import Script from "next/script"

export default function Layout({ children }) {
  const router = useRouter()
  const { userId } = useUser()

  useEffect(() => {
    if (!userId) {
      router.push(
        `/account/login?returnUrl=${encodeURIComponent(location.pathname)}`
      )
    }
  }, [])
  return (
    <div className="wrapper">
      <Head>
        <title>Finematter</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css2?family=Archivo:wght@400;500&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Navbar />
      <main className="main">{children}</main>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          window._mfq = window._mfq || [];
          (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/32138e5d-9343-4f9e-ad7a-5bc76ef9f776.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
          })();`,
        }}
      ></Script>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          window._mfq = window._mfq || [];
          (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//config.gorgias.chat/bundle-loader/01H11NFGK3CRB4H7REAKT0D3JP";
            document.getElementsByTagName("head")[0].appendChild(mf);
          })();`,
        }}
      ></Script>
    </div>
  )
}
