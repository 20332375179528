import { Store } from "react-notifications-component"

function notify({ type, title, message }) {
  Store.addNotification({
    title,
    message,
    type,
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
    },
  })
}

export function notifySuccess(message: string) {
  notify({ type: "success", title: "Success", message })
}

export function notifyError(message: string) {
  notify({ type: "danger", title: "Error", message })
}

export function notifyWarning(message: string) {
  notify({ type: "warning", title: "Warning", message })
}

export async function withErrorHandling(
  func: () => void,
  successMessage?: string
) {
  try {
    await func()

    if (successMessage) {
      notifySuccess(successMessage)
    }
  } catch (err) {
    notifyError(err.message)
  }
}
