import { useRouter } from "next/router"
import { useUser } from "contexts/UserContext"
import { deleteToken } from "services/token"
import styles from "./navbar.module.scss"
import MenuItem from "./MenuItem"
import { UserRole } from "graphql/gql/graphql"

export default function Menu() {
  const router = useRouter()

  const { designerHandles, removeAuthDetails } = useUser()

  // Users and designers are not a 1:1 mapping: one user can have several
  // associated designers. If there is only one designer associated with
  // the user, we can show the pieces for that designer. Otherwise, we
  // show the list of designers.
  // Some menu items will redirect users to different pages depending on
  // whether they have one or more designers associated.
  const hasOneDesignerAssociated = designerHandles.length === 1

  const logout = () => {
    deleteToken()
    removeAuthDetails()

    return router.push("/account/login")
  }

  return (
    <div className={styles.menu}>
      <MenuItem
        allowedRoles={[UserRole.Admin]}
        icon="search"
        label="Search"
        href="/search"
      />
      <MenuItem
        allowedRoles={[UserRole.Regular, UserRole.Admin]}
        icon="order"
        label="Orders"
        href="/designer-orders"
      />
      <MenuItem
        allowedRoles={[UserRole.Admin]}
        icon="discount"
        label="Discounts"
        href="/discounts"
      />

      {/* DesignerProfiles */}
      {hasOneDesignerAssociated ? (
        <MenuItem
          allowedRoles={[UserRole.Regular]}
          icon="designer"
          label="Profile"
          href={`/designers/${designerHandles[0]}`}
        />
      ) : (
        <MenuItem
          allowedRoles={[UserRole.Regular]}
          icon="designer"
          label="Profiles"
          href="/profiles"
        />
      )}

      {/* Pieces */}
      {hasOneDesignerAssociated ? (
        <MenuItem
          allowedRoles={[UserRole.Regular]}
          icon="piece"
          label="Pieces"
          href={`/designers/${designerHandles[0]}/pieces`}
        />
      ) : (
        <MenuItem
          allowedRoles={[UserRole.Regular, UserRole.Admin, UserRole.DataEntry]}
          icon="piece"
          label="Pieces"
          href="/pieces"
        />
      )}

      <MenuItem
        allowedRoles={[UserRole.Admin]}
        icon="shipments"
        label="Shipments"
        href="/shipments"
      />
      <MenuItem
        allowedRoles={[UserRole.Admin]}
        icon="photoshoots"
        label="Photoshoots"
        href="/photoshoots"
      />
      <MenuItem
        allowedRoles={[UserRole.Admin, UserRole.Finance]}
        icon="analytics"
        label="Analytics"
        href="/analytics"
      />
      <MenuItem
        allowedRoles={[UserRole.Admin]}
        icon="marketing"
        label="Referrals"
        href="/referrals"
      />
      <MenuItem
        allowedRoles={[UserRole.Regular]}
        icon="marketing"
        label="Marketing"
        href="/marketing"
      />
      <MenuItem
        allowedRoles={[
          UserRole.Admin,
          UserRole.ServiceOrderUs,
          UserRole.ServiceOrderUk,
          UserRole.ServiceOrderEu,
          UserRole.ServiceOrderAu,
        ]}
        icon="service-order"
        label="Service Orders"
        href="/service-orders/replate"
      />
      <MenuItem
        allowedRoles={[UserRole.Admin, UserRole.Regular, UserRole.Finance]}
        icon="payout"
        label="Payouts"
        href="/payouts"
      />
      <MenuItem
        allowedRoles={[UserRole.Admin]}
        icon="customer"
        label="Customers"
        href="/customers"
      />
      <MenuItem
        allowedRoles={[UserRole.Admin]}
        icon="designer"
        label="Designers"
        href="/designers"
      />
      <MenuItem
        allowedRoles={[UserRole.Admin]}
        icon="user"
        label="Users"
        href="/users"
      />
      <MenuItem
        allowedRoles={["All"]}
        icon="settings"
        label="Settings"
        href="/account/profile"
      />

      <div className={styles.menuItem} onClick={() => logout()}>
        <div className={styles.menuItemInner}>
          <img src="/assets/images/icons/log_out.svg" alt="Log out" />
          <span className={styles.label}>Log out</span>
        </div>
      </div>
    </div>
  )
}
