import styles from "./AdminForm.module.scss"
import classNames from "classnames"
import StandardButton from "components/Buttons/Standard"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { withErrorHandling } from "utils/notification"

export const GET_B2B_URL_QUERY = gql`
  query DesignerB2BUrl($handle: String!) {
    designer(handle: $handle) {
      b2bPageUrl
    }
  }
`

const UPDATE_B2B_URL_MUTATION = gql(`
  mutation DesignerUpdateFromAdminForm($handle: String!, $input: DesignerUpdateInput!) {
    designerUpdate(handle: $handle, input: $input) {
      b2bPageUrl
    }
  }
`)

const AdminForm = () => {
  const { isReady, query } = useRouter()
  const [isEdit, setIsEdit] = useState(false)
  const [isSubmittingUrl, setSubmittingUrl] = useState(false)
  const [currentUrl, setCurrentUrl] = useState("")

  const { data, loading, error } = useQuery(GET_B2B_URL_QUERY, {
    variables: { handle: query?.handle },
    skip: !isReady || !query?.handle,
  })
  const [updateB2BURL] = useMutation(UPDATE_B2B_URL_MUTATION)

  useEffect(() => {
    if (data?.designer?.b2bPageUrl) {
      setCurrentUrl(data.designer.b2bPageUrl)
    }
  }, [data, setCurrentUrl])

  const handleEditClick = () => {
    setIsEdit(true)
  }

  const handleAdminSubmit = async (event) => {
    event.preventDefault()

    const updateDesignerData = async () => {
      setSubmittingUrl(true)
      await updateB2BURL({
        variables: {
          handle: query.handle,
          input: {
            b2bPageUrl: currentUrl,
          },
        },
      })

      setSubmittingUrl(false)
      setIsEdit(false)
    }

    await withErrorHandling(
      updateDesignerData,
      "Profile has been updated successfully!"
    )
  }

  if (loading) return <div className={styles.adminForm}>Loading...</div>
  if (error) return <div>Error loading data</div>

  return (
    <div className={styles.adminContainer}>
      {!isEdit && (
        <div className={classNames(styles.infoWrapper)}>
          <div className={styles.infoInner}>
            <div className={styles.adminLabel}>B2B Service URL:</div>
            <button className={styles.urlInfo} onClick={() => setIsEdit(true)}>
              {currentUrl}
            </button>
          </div>
          <button onClick={handleEditClick} className={styles.editButton}>
            Edit
          </button>
        </div>
      )}
      {isEdit && (
        <form onSubmit={handleAdminSubmit} className={styles.adminForm}>
          <label>
            <span className={styles.adminLabel}>B2B Service URL</span>
            <input
              type="text"
              placeholder="Enter URL to bind user"
              className={classNames(styles.adminInput, "input")}
              value={currentUrl}
              onChange={(e) => setCurrentUrl(e.target.value)}
            />
          </label>
          <StandardButton
            className={styles.adminSubmitButton}
            name="Save"
            isSubmitting={isSubmittingUrl}
          />
        </form>
      )}
    </div>
  )
}

export default AdminForm
